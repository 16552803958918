<template>
    <table class="table table-bordered text-dark sub-table">
        <thead>
            <tr>
                <th>{{$t('title.supplier')}}</th>
                <th width="90">{{$t('title.total')}}</th>
                <th width="90">{{$t('title.currency')}}</th>
            </tr>
        </thead>
        <tbody>
            <fragment v-for="(value, index) in live_data">
                <fragment v-if="value[month] !== undefined">
                    <tr>
                        <td>{{value[month].supplier_name}}</td>
                        <td>{{value[month].total}}</td>
                        <td>{{value[month].currency}}</td>
                    </tr>
                    <tr class="d-none total-row">
                        <td>
                            Total
                            (<a href="javascript:;" @click="$emit('detailClicked')">{{$t('title.detail')}}</a>)
                        </td>
                        <td colspan="2" class="bg-gray-1">{{getTotal()}}</td>
                    </tr>
                </fragment>
            </fragment>
        </tbody>
    </table>
</template>
<script>
    export default {
        props: ['collection', 'month'],
        data() {
            return {

            }
        },
        methods: {
            getTotal() {
                let total = 0;
                const month = this.month
                const collection = this.collection

                _.map(collection, (value, index) => {
                    if(value[month] !== undefined) {
                        total += value[month].total;
                    }
                })

                return total;
            }
        },
        computed: {
            live_data: function() {
                return this.collection;
            }
        }
    }
</script>
<style lang="scss">
    .sub-table tbody tr.no-data:first-child {
        display: table-row !important;
    }

    .sub-table tbody tr.total-row:last-child {
        display: table-row !important;
    }
</style>
