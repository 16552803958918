<template>
    <div v-if="show">
        <div class="row">
            <div class="col-12 m-3 bg-gray-3 p-3">
                <div class="row">
                    <div class="col-5">
                        <div class="row">
                            <div class="col-6">
                                <b-form-group :label="$t('input.fromDate')">
                                    <b-form-datepicker placeholder="" v-model="filters.from_date"
                                                       class="mb-2"></b-form-datepicker>
                                </b-form-group>
                            </div>
                            <div class="col-6">
                                <b-form-group :label="$t('input.toDate')">
                                    <b-form-datepicker placeholder="" v-model="filters.to_date"
                                                       class="mb-2"></b-form-datepicker>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="col-7">
                        <fieldset aria-describedby="" class="form-group">
                            <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0 pb-0">
                                <pre> </pre>
                            </legend>
                            <div tabindex="-1" role="group" class="bv-no-focus-ring">
                                <b-button size='sm' variant="primary" class="mr-2"
                                          @click="handleCustomPeriodClick('month')"
                                          :disabled="global.pendingRequests > 0"
                                          :title="$t('button.thisMonth')" v-b-tooltip.hover>
                                    {{$t('button.thisMonth')}}
                                </b-button>
                                <b-button size='sm' variant="primary" class="mr-2"
                                          @click="handleCustomPeriodClick('week')"
                                          :disabled="global.pendingRequests > 0"
                                          :title="$t('button.thisWeek')" v-b-tooltip.hover>
                                    {{$t('button.thisWeek')}}
                                </b-button>
                                <b-button size='sm' variant="primary" class="mr-2"
                                          @click="handleCustomPeriodClick('year')"
                                          :disabled="global.pendingRequests > 0"
                                          :title="$t('button.thisYear')" v-b-tooltip.hover>
                                    {{$t('button.thisYear')}}
                                </b-button>
                                <b-button size='sm' variant="warning" class="mr-2"
                                          @click="handleResetFilterClick"
                                          :disabled="global.pendingRequests > 0"
                                          :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                    {{$t('button.reset')}}
                                </b-button>
                                <b-button size='sm' variant="success" button="submit" type="filled"
                                          @click="getList"
                                          :disabled="global.pendingRequests > 0"
                                          :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                    <clip-loader style="display: inline" :loading="true" color="#fff"
                                                 size="12px"
                                                 v-if="global.pendingRequests > 0"></clip-loader>
                                    {{$t('button.apply')}}
                                </b-button>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>

            <div class="col-12 accordion" role="tablist">
                <b-card no-body class="mb-4">
                    <b-card-header header-tag="header" class="p-0" role="tab">
                        <b-button block v-b-toggle.monthInboundLoadsTotalBySupplier>
                            <h5 class="mt-2 ml-3 mr-5 mb-3">
                                <div class="clearfix">
                                    <div class="pull-left">
                                        <strong>{{$t('title.monthlyInboundLoadsTotalBySupplier')}}</strong>
                                    </div>
                                    <div class="pull-right">
                                        <div class="mt-2">
                                            <i class="fa fa-plus open-indicator"></i>
                                            <i class="fa fa-minus close-indicator"></i>
                                        </div>
                                    </div>
                                </div>
                            </h5>
                        </b-button>
                    </b-card-header>
                    <b-collapse id="monthInboundLoadsTotalBySupplier" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <table class="table table-sm table-bordered" border="1">
                                <thead>
                                <tr>
                                    <th>{{$t('column.month')}}</th>
                                    <th>{{$t('column.suppliers')}}</th>
                                    <th>{{$t('column.country')}}</th>
                                    <th>{{$t('column.totalInbound')}}</th>
                                    <th>{{$t('column.totalCost')}}</th>
                                </tr>
                                </thead>
                                <tbody v-if="dataList.inboundLoads.data.length > 0">
                                <fragment v-for="(month, monthIndex) in dropdowns.months" :key="monthIndex">
                                    <fragment v-if="_.includes(dataList.inboundLoads.months, month.id)">
                                        <fragment v-for="(supplier, sIndex) in dataList.inboundLoads.suppliers"
                                                  :key="sIndex">
                                            <fragment v-for="(country, cIndex) in dataList.inboundLoads.countries"
                                                      :key="cIndex">
                                                <tr>
                                                    <td :rowspan="((Object.keys(dataList.inboundLoads.suppliers).length * Object.keys(dataList.inboundLoads.countries).length) + Object.keys(dataList.inboundLoads.suppliers).length + Object.keys(dataList.inboundLoads.countries).length + 1)"
                                                        v-if="cIndex <= 0 && sIndex <= 0"
                                                        class="align-middle text-center bg-gray">
                                                        <h5 class="m-0">{{$t(month.label)}}</h5>
                                                    </td>
                                                    <td><h6 class="m-0" v-if="cIndex <= 0">{{supplier.name}}</h6></td>
                                                    <td><h6 class="m-0">{{country}}</h6></td>
                                                    <td>{{ getQtySum(_.filter(dataList.inboundLoads.data, (item) =>(
                                                        item.month == month.id && item.sort_name == country &&
                                                        item.supplier_guid == supplier.id))) }}
                                                    </td>
                                                    <td>{{ getCostSum(_.filter(dataList.inboundLoads.data, (item) =>(
                                                        item.month == month.id && item.sort_name == country &&
                                                        item.supplier_guid == supplier.id))) }}
                                                    </td>
                                                </tr>
                                            </fragment>
                                        </fragment>

                                        <fragment v-for="(supplier, sIndex) in dataList.inboundLoads.suppliers"
                                                  :key="sIndex">
                                            <tr class="bg-gray-1">
                                                <td>
                                                    <h6 v-if="sIndex <= 0">
                                                        {{$t('column.totalByCarrier')}}
                                                    </h6>
                                                </td>
                                                <td>{{supplier.name}}</td>
                                                <td>{{ getQtySum(_.filter(dataList.inboundLoads.data, (item) =>
                                                    (item.month == month.id && item.supplier_guid == supplier.id))) }}
                                                </td>
                                                <td>{{ getCostSum(_.filter(dataList.inboundLoads.data, (item) =>
                                                    (item.month == month.id && item.supplier_guid == supplier.id))) }}
                                                </td>
                                            </tr>
                                        </fragment>

                                        <fragment v-for="(country, cIndex) in dataList.inboundLoads.countries"
                                                  :key="cIndex">
                                            <tr class="bg-gray-2">
                                                <td>
                                                    <h6 v-if="cIndex <= 0">
                                                        {{$t('column.totalByCountry')}}
                                                    </h6>
                                                </td>
                                                <td>{{country}}</td>
                                                <td>{{ getQtySum(_.filter(dataList.inboundLoads.data, (item) =>
                                                    (item.month == month.id && item.sort_name == country))) }}
                                                </td>
                                                <td>{{ getCostSum(_.filter(dataList.inboundLoads.data, (item) =>
                                                    (item.month == month.id && item.sort_name == country))) }}
                                                </td>
                                            </tr>
                                        </fragment>

                                        <tr class="bg-gray-3">
                                            <td colspan="2">
                                                <h5>{{$t('column.grandTotal')}}
                                                    <a href="javascript:;"
                                                       @click="() => getDetailByMonth('inboundLoad', month.id)">{{$t('button.detail')}}</a>
                                                </h5>
                                            </td>
                                            <td>{{ _.sumBy(dataList.inboundLoads.data, (i) => (i.month == month.id ?
                                                i.sub_qty : 0)) }}
                                            </td>

                                            <td>{{ _.sumBy(dataList.inboundLoads.data, (i) => (i.month == month.id ?
                                                i.sub_total : 0)).toFixed(2) }}
                                            </td>
                                        </tr>
                                    </fragment>
                                </fragment>
                                </tbody>
                                <tfoot v-else>
                                <tr rowspan="5">{{$t('title.noDataAvailable')}}</tr>
                                </tfoot>
                            </table>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>

            <div class="col-12 accordion" role="tablist">
                <b-card no-body class="mb-4">
                    <b-card-header header-tag="header" class="p-0" role="tab">
                        <b-button block v-b-toggle.outoundLoadsTotalBySupplier>
                            <h5 class="mt-2 ml-3 mr-5 mb-3">
                                <div class="clearfix">
                                    <div class="pull-left">
                                        <strong>{{$t('title.outboundLoadsTotalBySupplier')}}</strong>
                                    </div>
                                    <div class="pull-right">
                                        <div class="mt-2">
                                            <i class="fa fa-plus open-indicator"></i>
                                            <i class="fa fa-minus close-indicator"></i>
                                        </div>
                                    </div>
                                </div>
                            </h5>
                        </b-button>
                    </b-card-header>
                    <b-collapse id="outoundLoadsTotalBySupplier" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <table class="table table-sm table-bordered" border="1">
                                <thead>
                                <tr>
                                    <th>{{$t('column.month')}}</th>
                                    <th>{{$t('column.suppliers')}}</th>
                                    <th>{{$t('column.country')}}</th>
                                    <th>{{$t('column.totalOutbound')}}</th>
                                    <th>{{$t('column.totalCost')}}</th>
                                </tr>
                                </thead>
                                <tbody v-if="dataList.outboundLoads.data.length > 0">
                                <fragment
                                    v-for="(month, monthIndex) in dropdowns.months" :key="monthIndex">
                                    <fragment v-if="_.includes(dataList.outboundLoads.months, month.id)">
                                        <fragment v-for="(supplier, sIndex) in dataList.outboundLoads.suppliers"
                                                  :key="sIndex">
                                            <fragment v-for="(country, cIndex) in dataList.outboundLoads.countries"
                                                      :key="cIndex">
                                                <tr>
                                                    <td :rowspan="((Object.keys(dataList.outboundLoads.suppliers).length * Object.keys(dataList.outboundLoads.countries).length) + Object.keys(dataList.outboundLoads.suppliers).length + Object.keys(dataList.outboundLoads.countries).length + 1)"
                                                        v-if="cIndex <= 0 && sIndex <= 0"
                                                        class="align-middle text-center bg-gray">
                                                        <h5 class="m-0">{{$t(month.label)}}</h5>
                                                    </td>
                                                    <td><h6 class="m-0" v-if="cIndex <= 0">{{supplier.name}}</h6></td>
                                                    <td><h6 class="m-0">{{country}}</h6></td>
                                                    <td>{{ getQtySum(_.filter(dataList.outboundLoads.data, (item) =>(
                                                        item.month == month.id && item.sort_name == country &&
                                                        item.supplier_guid == supplier.id))) }}
                                                    </td>
                                                    <td>{{ _.sumBy(_.filter(dataList.outboundLoads.data, (item) =>(
                                                        item.month == month.id && item.sort_name == country &&
                                                        item.supplier_guid == supplier.id)), (i) => i.sub_total) }}
                                                    </td>
                                                </tr>
                                            </fragment>
                                        </fragment>

                                        <fragment v-for="(supplier, sIndex) in dataList.outboundLoads.suppliers"
                                                  :key="sIndex">
                                            <tr class="bg-gray-1">
                                                <td>
                                                    <h6 v-if="sIndex <= 0">
                                                        {{$t('column.totalByCarrier')}}
                                                    </h6>
                                                </td>
                                                <td>{{supplier.name}}</td>
                                                <td>{{ getQtySum(_.filter(dataList.outboundLoads.data, (item) =>
                                                    (item.month == month.id && item.supplier_guid == supplier.id))) }}
                                                </td>
                                                <td>{{ _.sumBy(_.filter(dataList.outboundLoads.data, (item) =>(
                                                    item.month == month.id && item.supplier_guid == supplier.id )), (i)
                                                    => i.sub_total) }}
                                                </td>
                                            </tr>
                                        </fragment>

                                        <fragment v-for="(country, cIndex) in dataList.outboundLoads.countries"
                                                  :key="cIndex">
                                            <tr class="bg-gray-2">
                                                <td>
                                                    <h6 v-if="cIndex <= 0">
                                                        {{$t('column.totalByCountry')}}
                                                    </h6>
                                                </td>
                                                <td>{{country}}</td>
                                                <td>{{ getQtySum(_.filter(dataList.outboundLoads.data, (item) =>
                                                    (item.month == month.id && item.sort_name == country))) }}
                                                </td>
                                                <td>{{ _.sumBy(_.filter(dataList.outboundLoads.data, (item) =>(
                                                    item.month == month.id && item.sort_name == country )), (i) =>
                                                    i.sub_total) }}
                                                </td>
                                            </tr>
                                        </fragment>

                                        <tr class="bg-gray-3">
                                            <td colspan="2">
                                                <h5>{{$t('column.grandTotal')}}
                                                    <a href="javascript:;"
                                                       @click="() => getDetailByMonth('outboundLoad', month.id)">{{$t('button.detail')}}</a>
                                                </h5>
                                            </td>
                                            <td>{{ _.sumBy(dataList.outboundLoads.data, (i) => (i.month == month.id ?
                                                i.sub_qty : 0)) }}
                                            </td>
                                            <td>{{ _.sumBy(dataList.outboundLoads.data, (i) => (i.month == month.id ?
                                                i.sub_total : 0)).toFixed(2) }}
                                            </td>
                                        </tr>
                                    </fragment>
                                </fragment>
                                </tbody>
                                <tfoot v-else>
                                <tr rowspan="5">{{$t('title.noDataAvailable')}}</tr>
                                </tfoot>
                            </table>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>

            <div class="col-6 accordion" role="tablist">
                <b-card no-body class="mb-4">
                    <b-card-header header-tag="header" class="p-0" role="tab">
                        <b-button block v-b-toggle.reexportLoadsTotalBySupplier>
                            <h5 class="mt-2 ml-3 mr-5 mb-3">
                                <div class="clearfix">
                                    <div class="pull-left">
                                        <strong>{{$t('title.reexportLoadsTotalBySupplier')}}</strong>
                                    </div>
                                    <div class="pull-right">
                                        <div class="mt-2">
                                            <i class="fa fa-plus open-indicator"></i>
                                            <i class="fa fa-minus close-indicator"></i>
                                        </div>
                                    </div>
                                </div>
                            </h5>
                        </b-button>
                    </b-card-header>
                    <b-collapse id="reexportLoadsTotalBySupplier" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <table class="table table-sm">
                                <thead>
                                <tr>
                                    <th>{{$t('title.month')}}</th>
                                    <th>{{$t('title.data')}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th>{{$t('month.january')}}</th>
                                    <th>
                                        <display-data-table v-if="dataList.reexportLoads.length"
                                                            :collection="dataList.reexportLoads"
                                                            @detailClicked="() => getDetailByMonth('reexportLoad', 1)"
                                                            month="January"></display-data-table>
                                    </th>
                                </tr>
                                <tr>
                                    <th>{{$t('month.february')}}</th>
                                    <th>
                                        <display-data-table v-if="dataList.reexportLoads.length"
                                                            @detailClicked="() => getDetailByMonth('reexportLoad', 2)"
                                                            :collection="dataList.reexportLoads"
                                                            month="February"></display-data-table>
                                    </th>
                                </tr>
                                <tr>
                                    <th>{{$t('month.march')}}</th>
                                    <th>
                                        <display-data-table v-if="dataList.reexportLoads.length"
                                                            @detailClicked="() => getDetailByMonth('reexportLoad', 3)"
                                                            :collection="dataList.reexportLoads"
                                                            month="March"></display-data-table>
                                    </th>
                                </tr>
                                <tr>
                                    <th>{{$t('month.april')}}</th>
                                    <th>
                                        <display-data-table v-if="dataList.reexportLoads.length"
                                                            @detailClicked="() => getDetailByMonth('reexportLoad', 4)"
                                                            :collection="dataList.reexportLoads"
                                                            month="April"></display-data-table>
                                    </th>
                                </tr>
                                <tr>
                                    <th>{{$t('month.may')}}</th>
                                    <th>
                                        <display-data-table v-if="dataList.reexportLoads.length"
                                                            @detailClicked="() => getDetailByMonth('reexportLoad', 5)"
                                                            :collection="dataList.reexportLoads"
                                                            month="May"></display-data-table>
                                    </th>
                                </tr>
                                <tr>
                                    <th>{{$t('month.june')}}</th>
                                    <th>
                                        <display-data-table v-if="dataList.reexportLoads.length"
                                                            @detailClicked="() => getDetailByMonth('reexportLoad', 6)"
                                                            :collection="dataList.reexportLoads"
                                                            month="June"></display-data-table>
                                    </th>
                                </tr>
                                <tr>
                                    <th>{{$t('month.july')}}</th>
                                    <th>
                                        <display-data-table v-if="dataList.reexportLoads.length"
                                                            @detailClicked="() => getDetailByMonth('reexportLoad', 7)"
                                                            :collection="dataList.reexportLoads"
                                                            month="July"></display-data-table>
                                    </th>
                                </tr>
                                <tr>
                                    <th>{{$t('month.august')}}</th>
                                    <th>
                                        <display-data-table v-if="dataList.reexportLoads.length"
                                                            @detailClicked="() => getDetailByMonth('reexportLoad', 8)"
                                                            :collection="dataList.reexportLoads"
                                                            month="August"></display-data-table>
                                    </th>
                                </tr>
                                <tr>
                                    <th>{{$t('month.september')}}</th>
                                    <th>
                                        <display-data-table v-if="dataList.reexportLoads.length"
                                                            @detailClicked="() => getDetailByMonth('reexportLoad', 9)"
                                                            :collection="dataList.reexportLoads"
                                                            month="September"></display-data-table>
                                    </th>
                                </tr>
                                <tr>
                                    <th>{{$t('month.october')}}</th>
                                    <th>
                                        <display-data-table v-if="dataList.reexportLoads.length"
                                                            @detailClicked="() => getDetailByMonth('reexportLoad', 10)"
                                                            :collection="dataList.reexportLoads"
                                                            month="October"></display-data-table>
                                    </th>
                                </tr>
                                <tr>
                                    <th>{{$t('month.november')}}</th>
                                    <th>
                                        <display-data-table v-if="dataList.reexportLoads.length"
                                                            @detailClicked="() => getDetailByMonth('reexportLoad', 11)"
                                                            :collection="dataList.reexportLoads"
                                                            month="November"></display-data-table>
                                    </th>
                                </tr>
                                <tr>
                                    <th>{{$t('month.december')}}</th>
                                    <th>
                                        <display-data-table v-if="dataList.reexportLoads.length"
                                                            @detailClicked="() => getDetailByMonth('reexportLoad', 12)"
                                                            :collection="dataList.reexportLoads"
                                                            month="December"></display-data-table>
                                    </th>
                                </tr>
                                </tbody>
                            </table>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>

            <div class="col-6 accordion" role="tablist">
                <b-card no-body class="mb-4">
                    <b-card-header header-tag="header" class="p-0" role="tab">
                        <b-button block v-b-toggle.spotLoadsTotalBySupplier>
                            <h5 class="mt-2 ml-3 mr-5 mb-3">
                                <div class="clearfix">
                                    <div class="pull-left">
                                        <strong>{{$t('title.spotLoadsTotalBySupplier')}}</strong>
                                    </div>
                                    <div class="pull-right">
                                        <div class="mt-2">
                                            <i class="fa fa-plus open-indicator"></i>
                                            <i class="fa fa-minus close-indicator"></i>
                                        </div>
                                    </div>
                                </div>
                            </h5>
                        </b-button>
                    </b-card-header>
                    <b-collapse id="spotLoadsTotalBySupplier" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <table class="table table-sm">
                                <thead>
                                <tr>
                                    <th>{{$t('title.month')}}</th>
                                    <th>{{$t('title.data')}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th>{{$t('month.january')}}</th>
                                    <th>
                                        <display-data-table v-if="dataList.spotLoads.length"
                                                            :collection="dataList.spotLoads"
                                                            month="January"></display-data-table>
                                    </th>
                                </tr>
                                <tr>
                                    <th>{{$t('month.february')}}</th>
                                    <th>
                                        <display-data-table v-if="dataList.spotLoads.length"
                                                            :collection="dataList.spotLoads"
                                                            month="February"></display-data-table>
                                    </th>
                                </tr>
                                <tr>
                                    <th>{{$t('month.march')}}</th>
                                    <th>
                                        <display-data-table v-if="dataList.spotLoads.length"
                                                            :collection="dataList.spotLoads"
                                                            month="March"></display-data-table>
                                    </th>
                                </tr>
                                <tr>
                                    <th>{{$t('month.april')}}</th>
                                    <th>
                                        <display-data-table v-if="dataList.spotLoads.length"
                                                            :collection="dataList.spotLoads"
                                                            month="April"></display-data-table>
                                    </th>
                                </tr>
                                <tr>
                                    <th>{{$t('month.may')}}</th>
                                    <th>
                                        <display-data-table v-if="dataList.spotLoads.length"
                                                            :collection="dataList.spotLoads"
                                                            month="May"></display-data-table>
                                    </th>
                                </tr>
                                <tr>
                                    <th>{{$t('month.june')}}</th>
                                    <th>
                                        <display-data-table v-if="dataList.spotLoads.length"
                                                            :collection="dataList.spotLoads"
                                                            month="June"></display-data-table>
                                    </th>
                                </tr>
                                <tr>
                                    <th>{{$t('month.july')}}</th>
                                    <th>
                                        <display-data-table v-if="dataList.spotLoads.length"
                                                            :collection="dataList.spotLoads"
                                                            month="July"></display-data-table>
                                    </th>
                                </tr>
                                <tr>
                                    <th>{{$t('month.august')}}</th>
                                    <th>
                                        <display-data-table v-if="dataList.spotLoads.length"
                                                            :collection="dataList.spotLoads"
                                                            month="August"></display-data-table>
                                    </th>
                                </tr>
                                <tr>
                                    <th>{{$t('month.september')}}</th>
                                    <th>
                                        <display-data-table v-if="dataList.spotLoads.length"
                                                            :collection="dataList.spotLoads"
                                                            month="September"></display-data-table>
                                    </th>
                                </tr>
                                <tr>
                                    <th>{{$t('month.october')}}</th>
                                    <th>
                                        <display-data-table v-if="dataList.spotLoads.length"
                                                            :collection="dataList.spotLoads"
                                                            month="October"></display-data-table>
                                    </th>
                                </tr>
                                <tr>
                                    <th>{{$t('month.november')}}</th>
                                    <th>
                                        <display-data-table v-if="dataList.spotLoads.length"
                                                            :collection="dataList.spotLoads"
                                                            month="November"></display-data-table>
                                    </th>
                                </tr>
                                <tr>
                                    <th>{{$t('month.december')}}</th>
                                    <th>
                                        <display-data-table v-if="dataList.spotLoads.length"
                                                            :collection="dataList.spotLoads"
                                                            month="December"></display-data-table>
                                    </th>
                                </tr>
                                </tbody>
                            </table>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>

            <div class="col-12 accordion" role="tablist">
                <b-card no-body class="mb-4">
                    <b-card-header header-tag="header" class="p-0" role="tab">
                        <b-button block v-b-toggle.dealerToDealerLoadsTotalBySupplier>
                            <h5 class="mt-2 ml-3 mr-5 mb-3">
                                <div class="clearfix">
                                    <div class="pull-left">
                                        <strong>{{$t('title.dealerToDealerLoadsTotalBySupplier')}}</strong>
                                    </div>
                                    <div class="pull-right">
                                        <div class="mt-2">
                                            <i class="fa fa-plus open-indicator"></i>
                                            <i class="fa fa-minus close-indicator"></i>
                                        </div>
                                    </div>
                                </div>
                            </h5>
                        </b-button>
                    </b-card-header>
                    <b-collapse id="dealerToDealerLoadsTotalBySupplier" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <table class="table table-sm table-bordered" border="1">
                                <thead>
                                <tr>
                                    <th>{{$t('column.month')}}</th>
                                    <th>{{$t('column.suppliers')}}</th>
                                    <th>{{$t('column.country')}}</th>
                                    <th>{{$t('column.totalInbound')}}</th>
                                    <th>{{$t('column.totalCost')}}</th>
                                </tr>
                                </thead>
                                <tbody v-if="dataList.dealerToDealerLoads.data.length > 0">
                                <fragment v-for="(month, monthIndex) in dropdowns.months" :key="monthIndex">
                                    <fragment v-if="_.includes(dataList.dealerToDealerLoads.months, month.id)">
                                        <fragment v-for="(supplier, sIndex) in dataList.dealerToDealerLoads.suppliers"
                                                  :key="sIndex">
                                            <fragment
                                                v-for="(country, cIndex) in dataList.dealerToDealerLoads.countries"
                                                :key="cIndex">
                                                <tr>
                                                    <td :rowspan="((Object.keys(dataList.dealerToDealerLoads.suppliers).length * Object.keys(dataList.dealerToDealerLoads.countries).length) + Object.keys(dataList.dealerToDealerLoads.suppliers).length + Object.keys(dataList.dealerToDealerLoads.countries).length + 1)"
                                                        v-if="cIndex <= 0 && sIndex <= 0"
                                                        class="align-middle text-center bg-gray">
                                                        <h5 class="m-0">{{$t(month.label)}}</h5>
                                                    </td>
                                                    <td><h6 class="m-0" v-if="cIndex <= 0">{{supplier.name}}</h6></td>
                                                    <td><h6 class="m-0">{{country}}</h6></td>
                                                    <td>{{ getQtySum(_.filter(dataList.dealerToDealerLoads.data, (item)
                                                        =>( item.month == month.id && item.sort_name == country &&
                                                        item.supplier_guid == supplier.id))) }}
                                                    </td>
                                                    <td>{{ getCostSum(_.filter(dataList.dealerToDealerLoads.data, (item)
                                                        =>( item.month == month.id && item.sort_name == country &&
                                                        item.supplier_guid == supplier.id))) }}
                                                    </td>
                                                </tr>
                                            </fragment>
                                        </fragment>

                                        <fragment v-for="(supplier, sIndex) in dataList.dealerToDealerLoads.suppliers"
                                                  :key="sIndex">
                                            <tr class="bg-gray-1">
                                                <td>
                                                    <h6 v-if="sIndex <= 0">
                                                        {{$t('column.totalByCarrier')}}
                                                    </h6>
                                                </td>
                                                <td>{{supplier.name}}</td>
                                                <td>{{ getQtySum(_.filter(dataList.dealerToDealerLoads.data, (item) =>
                                                    (item.month == month.id && item.supplier_guid == supplier.id))) }}
                                                </td>
                                                <td>{{ getCostSum(_.filter(dataList.dealerToDealerLoads.data, (item) =>
                                                    (item.month == month.id && item.supplier_guid == supplier.id))) }}
                                                </td>
                                            </tr>
                                        </fragment>

                                        <fragment v-for="(country, cIndex) in dataList.dealerToDealerLoads.countries"
                                                  :key="cIndex">
                                            <tr class="bg-gray-2">
                                                <td>
                                                    <h6 v-if="cIndex <= 0">
                                                        {{$t('column.totalByCountry')}}
                                                    </h6>
                                                </td>
                                                <td>{{country}}</td>
                                                <td>{{ getQtySum(_.filter(dataList.dealerToDealerLoads.data, (item) =>
                                                    (item.month == month.id && item.sort_name == country))) }}
                                                </td>
                                                <td>{{ getCostSum(_.filter(dataList.dealerToDealerLoads.data, (item) =>
                                                    (item.month == month.id && item.sort_name == country))) }}
                                                </td>
                                            </tr>
                                        </fragment>

                                        <tr class="bg-gray-3">
                                            <td colspan="2">
                                                <h5>{{$t('column.grandTotal')}}
                                                    <a href="javascript:;"
                                                       @click="() => getDetailByMonth('dealerToDealerLoad', month.id)">{{$t('button.detail')}}</a>
                                                </h5>
                                            </td>
                                            <td>{{ _.sumBy(dataList.dealerToDealerLoads.data, (i) => (i.month ==
                                                month.id ? i.sub_qty : 0)) }}
                                            </td>
                                            <td>{{ _.sumBy(dataList.dealerToDealerLoads.data, (i) => (i.month ==
                                                month.id ? i.sub_total : 0)).toFixed(2) }}
                                            </td>
                                        </tr>
                                    </fragment>
                                </fragment>
                                </tbody>
                                <tfoot v-else>
                                <tr rowspan="5">{{$t('title.noDataAvailable')}}</tr>
                                </tfoot>
                            </table>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>

            <div class="col-6 accordion" role="tablist">
                <div class="card">
                    <div class="card-header card-header-flex">
                        <h5 class="mt-3 ml-0 mr-3 mb-0 w-100">
                            <strong>{{$t('title.averageDeliveryByCountry')}}</strong>
                        </h5>
                    </div><!-- /.card-header -->
                    <div class="card-body">
                        <average-country-delivery :chart-data="dataList.countryDeliveryAverage"
                                                  v-if="global.pendingRequests <= 0"></average-country-delivery>
                    </div>
                </div><!-- /.card -->
            </div>

            <a-drawer
                placement="right"
                :width="'80%'"
                :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                :closable="false"
                @close="dataDetailList.visible = false"
                :visible="dataDetailList.visible"
                :zIndex="10"
                :title="$t(dataDetailList.title)+' '+$t('title.detail')"
            >
                <b-row>
                    <b-col sm="12" v-if="dataDetailList.inboundLoadList.length > 0">
                        <table class="table table-striped table-sm">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>{{$t('column.loadNumber')}}</th>
                                <th>{{$t('column.route')}}</th>
                                <th>{{$t('column.supplier')}}</th>
                                <th>{{$t('column.unloadDate')}}</th>
                                <th>{{$t('column.unitPrice')}}</th>
                                <th>{{$t('column.qty')}}</th>
                                <th>{{$t('column.total')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in dataDetailList.inboundLoadList">
                                <td>{{index + 1}}</td>
                                <td>{{item.load_number}}</td>
                                <td>{{item.route_name}}</td>
                                <td>{{item.supplier_name}}</td>
                                <td>{{ $global.utcDateToLocalDate(item.actual_unloading_time) }}</td>
                                <td>{{item.price_unit}}</td>
                                <td>{{item.qty}}</td>
                                <td>{{item.price}}</td>
                            </tr>
                            <tr>
                                <td colspan="8" class="text-right">
                                    <h3>{{$t('column.grandTotal')}}:
                                        {{getQtySumDetail(dataDetailList.inboundLoadList)}}</h3>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </b-col>
                    <b-col sm="12" v-if="dataDetailList.outboundLoadList.length > 0">
                        <table class="table table-striped table-sm">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>{{$t('column.loadNumber')}}</th>
                                <th>{{$t('column.route')}}</th>
                                <th>{{$t('column.supplier')}}</th>
                                <th>{{$t('column.unloadDate')}}</th>
                                <th>{{$t('column.unitPrice')}}</th>
                                <th>{{$t('column.qty')}}</th>
                                <th>{{$t('column.total')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in dataDetailList.outboundLoadList">
                                <td>{{index + 1}}</td>
                                <td>{{item.load_number}}</td>
                                <td>{{item.route_name}}</td>
                                <td>{{item.supplier_name}}</td>
                                <td>{{ $global.utcDateToLocalDate(item.actual_unloading_time) }}</td>
                                <td>{{item.price_unit}}</td>
                                <td>{{item.qty}}</td>
                                <td>{{item.price}}</td>
                            </tr>
                            <tr>
                                <td colspan="8" class="text-right">
                                    <h3>{{$t('column.grandTotal')}}:
                                        {{getQtySumDetail(dataDetailList.outboundLoadList)}}</h3>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </b-col>
                    <b-col sm="12" v-if="dataDetailList.dealerToDealerLoadList.length > 0">
                        <table class="table table-striped table-sm">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>{{$t('column.loadNumber')}}</th>
                                <th>{{$t('column.route')}}</th>
                                <th>{{$t('column.supplier')}}</th>
                                <th>{{$t('column.unloadDate')}}</th>
                                <th>{{$t('column.unitPrice')}}</th>
                                <th>{{$t('column.qty')}}</th>
                                <th>{{$t('column.total')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in dataDetailList.dealerToDealerLoadList">
                                <td>{{index + 1}}</td>
                                <td>{{item.load_number}}</td>
                                <td>{{item.route_name}}</td>
                                <td>{{item.supplier_name}}</td>
                                <td>{{ $global.utcDateToLocalDate(item.actual_unloading_time) }}</td>
                                <td>{{item.price_unit}}</td>
                                <td>{{item.qty}}</td>
                                <td>{{item.price}}</td>
                            </tr>
                            <tr>
                                <td colspan="8" class="text-right">
                                    <h3>{{$t('column.grandTotal')}}:
                                        {{getQtySumDetail(dataDetailList.dealerToDealerLoadList)}}</h3>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </b-col>
                    <b-col sm="12" v-if="dataDetailList.reexportLoadList.length > 0">
                        <table class="table table-striped table-sm">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>{{$t('column.loadNumber')}}</th>
                                <th>{{$t('column.supplier')}}</th>
                                <th>{{$t('column.unloadDate')}}</th>
                                <th>{{$t('column.unitPrice')}}</th>
                                <th>{{$t('column.qty')}}</th>
                                <th>{{$t('column.total')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in dataDetailList.reexportLoadList">
                                <td>{{index + 1}}</td>
                                <td>{{item.load_number}}</td>
                                <td>{{item.supplier_name}}</td>
                                <td>{{ $global.utcDateToLocalDate(item.actual_unloading_time) }}</td>
                                <td>{{item.price_unit}}</td>
                                <td>{{item.qty}}</td>
                                <td>{{item.price_unit*item.qty}}</td>
                            </tr>
                            <tr>
                                <td colspan="7" class="text-right">
                                    <h3>{{$t('column.grandTotal')}}:
                                        {{getQtySumDetail(dataDetailList.reexportLoadList)}}</h3>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>
            </a-drawer>
        </div>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Datepicker from 'vuejs-datepicker'
    import {request} from '../../util/Request'
    import Treeselect from '@riophae/vue-treeselect'
    import DisplayDataTable from './components/DisplayDataTable'
    import AverageCountryDelivery from './components/AverageCountryDelivery'
    import moment from 'moment-timezone'
    import qs from 'qs'

    const DEFAULT_FILTER_DATE = {
        from_date: '',
        to_date: '',
    }

    const MONTHS = [
        {id: 1, label: 'month.january'},
        {id: 2, label: 'month.february'},
        {id: 3, label: 'month.march'},
        {id: 4, label: 'month.april'},
        {id: 5, label: 'month.may'},
        {id: 6, label: 'month.june'},
        {id: 7, label: 'month.july'},
        {id: 8, label: 'month.august'},
        {id: 9, label: 'month.september'},
        {id: 10, label: 'month.october'},
        {id: 11, label: 'month.november'},
        {id: 12, label: 'month.december'},
    ]

    const DEFAULT_DATA_LIST = {
        inboundLoads: {data: [], suppliers: [], countries: []},
        outboundLoads: {data: [], suppliers: [], countries: []},
        reexportLoads: [],
        dealerToDealerLoads: {data: [], suppliers: [], countries: []},
        spotLoads: [],
        countryDeliveryAverage: {labels: [], datasets: []}
    }

    const colors = [
        'rgb(255,160,122)',
        'rgb(250,128,114)',
        'rgb(233,150,122)',
        'rgb(240,128,128)',
        'rgb(205,92,92)',
        'rgb(220,20,60)',
        'rgb(178,34,34)',
        'rgb(255,0,0)',
        'rgb(139,0,0)',
        'rgb(255,127,80)',
        'rgb(255,99,71)',
        'rgb(255,69,0)',
        'rgb(255,215,0)',
        'rgb(255,165,0)',
        'rgb(255,140,0)',
        'rgb(255,255,224)',
        'rgb(255,250,205)',
        'rgb(255,239,213)',
        'rgb(255,228,181)',
        'rgb(255,218,185)',
        'rgb(238,232,170)',
        'rgb(240,230,140)',
        'rgb(189,183,107)',
        'rgb(255,255,0)',
        'rgb(124,252,0)',
        'rgb(50,205,50)',
        'rgb(0,255,0)',
        'rgb(34,139,34)',
        'rgb(0,100,0)',
        'rgb(0,255,127)',
        'rgb(0,250,154)',
        'rgb(152,251,152)',
        'rgb(46,139,87)',
        'rgb(176,224,230)',
        'rgb(135,206,250)',
        'rgb(135,206,235)',
        'rgb(0,191,255)',
        'rgb(30,144,255)',
        'rgb(100,149,237)',
        'rgb(70,130,180)',
        'rgb(65,105,225)',
        'rgb(0,0,205)',
    ]

    export default {
        components: {
            Datepicker,
            Treeselect,
            DisplayDataTable,
            AverageCountryDelivery
        },
        data() {
            return {
                dropdowns: {
                    months: MONTHS,
                    currencies: [],
                },
                show: true,
                dataList: {...DEFAULT_DATA_LIST},
                filters: {...DEFAULT_FILTER_DATE},
                dataDetailList: {
                    inboundLoadList: [],
                    outboundLoadList: [],
                    dealerToDealerLoadList: [],
                    reexportLoadList: [],
                    visible: false,
                    title: null
                }
            }
        },
        mounted() {
            this.$title = this.$t('topBar.navigations.modules.analytics')
            this.getCurrencies()
        },
        methods: {
            handleClick() {
                console.log('Hellllo')
            },
            setOperation(operation = 'add', operationToken = null) {
                this.$router.replace({
                    query: Object.assign({},
                        this.$route.query,
                        {
                            ...this.listQueryParams,
                            operation: operation,
                            oToken: operationToken,
                        },
                    ),
                }).then(() => {
                }).catch(() => {
                })
            },
            async getList() {
                try {
                    this.dataList = {...DEFAULT_DATA_LIST}
                    const params = {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    }

                    const response = await request({
                        url: '/analytics',
                        method: 'get',
                        params: params,
                        paramsSerializer: ((params) => qs.stringify(params)),
                    })

                    const {data} = response
                    this.dataList.inboundLoads = data.inboundLoads
                    this.dataList.outboundLoads = data.outboundLoads
                    this.dataList.reexportLoads = data.reexportLoads
                    this.dataList.dealerToDealerLoads = data.dealerToDealerLoads
                    this.dataList.spotLoads = data.spotLoads

                    const countryDeliveryAverageData = {labels: [], datasets: []};
                    const countryDeliveryAverageDataSet = [];
                    _.map(data.countryDeliveryAverage, (value, index) => {
                        countryDeliveryAverageData.labels.push(value.country);
                        countryDeliveryAverageDataSet.push(value.average);
                    })

                    countryDeliveryAverageData.datasets = [{
                        data: countryDeliveryAverageDataSet,
                        backgroundColor: colors
                    }];
                    this.dataList.countryDeliveryAverage = countryDeliveryAverageData
                } catch (e) {

                }
            },
            async getCurrencies() {
                try {
                    const response = await request({
                        url: '/dropdowns/currencies',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.currencies = data
                    this.getList();
                } catch (e) {
                    this.dropdowns.currencies = []
                }
            },
            async getDetailByMonth(type, monthId) {
                try {
                    const params = {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        month: monthId,
                        type: type
                    }

                    const response = await request({
                        url: `analytics/detail/${type}`,
                        params: params,
                        paramsSerializer: ((params) => qs.stringify(params)),
                    })

                    const {data} = response
                    this.dataDetailList[`${type}List`] = data[type]
                    this.dataDetailList.visible = true
                    this.dataDetailList.title = this.$t(`title.${type}`)
                } catch (e) {
                    this.dataDetailList.visible = false
                }
            },
            hasListAccess() {
                return this.$global.hasPermission('analyticsview')
            },
            handleCustomPeriodClick(type) {
                if (type === 'week') {
                    this.filters.from_date = moment().startOf('isoWeek').format('YYYY-MM-DD')
                    this.filters.to_date = moment().endOf('isoWeek').format('YYYY-MM-DD')
                } else if (type === 'month') {
                    this.filters.from_date = moment().startOf('month').format('YYYY-MM-DD')
                    this.filters.to_date = moment().endOf('month').format('YYYY-MM-DD')
                } else if (type === 'year') {
                    this.filters.from_date = moment().startOf('year').format('YYYY-MM-DD')
                    this.filters.to_date = moment().endOf('year').format('YYYY-MM-DD')
                }

                this.getList()
            },
            handleResetFilterClick() {
                this.filters = {...DEFAULT_FILTER_DATE}

                this.getList()
            },
            getQtySum(data) {
                let total = 0

                _.map(data, (item) => {
                    total += item.sub_qty
                })

                return total
            },
            getCostSum(data) {
                let total = 0
                let currencyId = data.length > 0 ? data[0].currency : 0;
                let currency = ''

                if (currencyId > 0) {
                    currency = (_.find(this.dropdowns.currencies, (i) => i.id == currencyId) || {}).label
                }

                _.map(data, (item) => {
                    total += item.price_unit * item.sub_qty
                })

                return `${total} ${currency}`
            },
            getQtySumDetail(data) {
                let total = 0

                _.map(data, (item) => {
                    total += item.price
                })

                return total
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>

<style lang="scss">
    .card {
        .btn.btn-secondary, .show > .btn.btn-secondary, .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
            background-color: rgba(0, 0, 0, 0.03);
            border-color: rgba(0, 0, 0, 0.03);
            background: transparent;
        }
    }

    .card-header .collapsed {
        .open-indicator {
            display: block;
        }

        .close-indicator {
            display: none;
        }
    }

    .card-header .not-collapsed {
        .open-indicator {
            display: none;
        }

        .close-indicator {
            display: block;
        }
    }
</style>
